.bottom-nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.icon-container {
  text-align: center;
  color: #888; /* Couleur par défaut */
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;  /* Enlève le fond */
  border: none;  /* Enlève les bordures si elles apparaissent */
  outline: none;
}

.icon-container.active {
  color: var(--akanthas-cyan);
  background: none;  /* Enlève le fond */
  border: none;  /* Enlève les bordures si elles apparaissent */
  outline: none;
}

.icon-container p.icon-text {
  margin: 0;
  font-size: 0.65rem;
}

.icon-container:hover {
  color: var(--akanthas-yellow); /* Couleur lorsque tu survoles une icône */
}

.notifications-icon-wrapper {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -3px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}