.content-table-block{
  width: 50%;
  flex-direction: column;
}

.header{
  &-display-line{
    display: none;
  }

  &-display-block{
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: auto 10px;
  }
}

.table{
  &-display-block {
    display: flex;
  }
  
  &-block-card{
    display: flex;
    width: 98%;
    background: var(--white);
    margin: 8px 8px 8px 0;
    border-radius: 8px;
  }

  &-line-block{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    cursor: pointer;
    justify-content: space-around;
    border-radius: 5px;
    min-height: 275px;
  }
}

.table-line-block:hover{
  background-color: #F5FFFF;
}

.data{
  &-double-block {
    display: flex;
    flex-direction: column;
  }

  &-image-block{
    display:none;
  }

  &-address-block{
    display: none;
  }

  &-image-display{
    margin: auto 5px;
  }

  &-table-block{
    display: flex;
    flex-direction: column;
  }

  &-table-display{
    display: none;
  }

  &-content-block {
    width: 90%;
  }

  &-action-block {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    margin: 4px auto;
  }
}

.image{
  &-value-block {
    display: flex;
    justify-content: space-between;
    background-color: var(--akanthas-cyan);
    color: var(--white);
  }

  &-content-block{
    display: flex;
    margin: 2px;
    & span{
      margin: auto 2px;
    }
  }
  
  &-level{
  
    &-value-block{
      display: flex;
      flex-direction: column-reverse;
      border-radius: 2px;
      border: solid 2px var(--white);
      height: 100%;
      width: 15px;
    }
  }

}

.data-double-block, .data-table-block {
	margin: 3px 0;
}

.header-display-bloc, .table-line-block {
	margin: 5px 8px;
}

.table-column-block, .table-line-block{
	font-size: 14px ;
}

.highlight-container {
  display: flex;
  align-items: center;
  // display: inline-block;
  position: relative;
}

.highlight-container::before {
  content: '';
  position: absolute;
  top: -6px;
  left: -8px;
  right: -8px;
  bottom: -6px;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s;
}

.highlight-container:hover::before {
  border-color: var(--silver);
}