
.horizontal-slider {
  width: 100%;
  height: 30px;
  margin-top: auto;
}

.example-thumb.example-thumb-0 {
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid var(--akanthas-cyan);
}

.example-thumb.example-thumb-1 {
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right:11px solid var(--akanthas-cyan);
}


.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: red;
}

.example-track.example-track-0 {
  background: var(--status-grey);
}

.example-track.example-track-1 {
  background: var(--akanthas-cyan);
}

.example-track.example-track-2 {
  background: var(--status-grey);
}

.horizontal-slider .example-track {
  top: 14px;
  height: 5px;
}

.horizontal-slider .example-thumb {
  top: 6px;
  width: 11px;
  outline: none;
  height: 11px;
  line-height: 38px;
  font-size: 0px;
}

.label-slider-value{
  display: flex;
  justify-content: space-between;
}

.example-thumb {
	z-index: 0 !important;
}
