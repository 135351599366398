
.base {
  background-color: var(--light-grey);
  padding: 10px 25px;
  padding-top: 0px;
  min-height: 100vh;
  max-width: 100vw;
}

.base > h1 {
  color: var(--akanthas-blue);
}

.display-linebreak {
  white-space: pre-line;
}
