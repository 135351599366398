
.progress-bar {
  position: relative;
  height: 10px;
  width: 80%;
  border-radius: 50px;
  border: 1px solid #333;
  margin-right: 10px;
  text-decoration: none;
}
