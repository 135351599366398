
.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: var(--gray);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
