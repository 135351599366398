
.button-main {
  margin-right: 10px;
}

.data-action {
	flex-direction: row-reverse !important;
	position: absolute;
  height: 100%;
  & a{
    margin: auto 0;
  }
}

.data-action, .column-action {
	width: 20% !important;
	position: absolute;
  right: 0;
  top: 0;
}

.ico-action {
	font-size: 20px;
	margin: auto 5px;
  color: var(--akanthas-cyan);
}

.icon-header {
	width: auto;
	padding: 0px;
	font-size: 22px;
  color: var(--akanthas-cyan);
  margin: auto;
}

.icon-header:hover, .button-action:hover{
  color: var(--akanthas-yellow);
}

.icon-header, .operation-data{
  cursor: pointer;
}

.delete-button {
	width: 100%;
	border-radius: 4px;
	padding: 8px;
	font-size: 16px;
	margin: auto 0;
	border: none;
	color: var(--light);
	height: auto;
	line-height: initial;
  background: var(--akanthas-cyan) ;
}

.button{

  &-submit:hover, &-danger:hover,.delete-button:hover {
    background-color: var(--akanthas-yellow);
  }
  
  &-submit{
    background: var(--akanthas-cyan) ;
  }
  
  &-action,&-view {
    color: var(--akanthas-cyan);
  }

  &-view{
    margin: auto 0;
    font-size: 20px;
  }

  &-nothing{
    margin: auto 0;
    border: none;
    background-color: white;
    color: var(--akanthas-cyan);
    font-size: 15px;
  }

  &-action{
    margin: auto 20px;
    width: 2%;
    cursor: pointer;
    font-size: 18px;
  }
  
  &-submit, &-cancel, &-danger{
    padding: 10px 40px;
    border-radius: 4px;
    color: var(--white);
    border: none;
  }

  &-danger{
    border: none;
    background-color: var(--akanthas-red);
  }

  &-map-active {
    background-color: white;
    border: 0px;
    color: var(--akanthas-cyan);
    height: 25px;
    width: 25px;
  }

  &-map {
    background-color: white;
    border: 0px;
    color: grey;
    height: 25px;
    width: 25px;
    :hover {
      color: var(--silver);
    }
    
  }
}

.previous{
  &-inactive {
    border: none;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--silver);
    color: white;
  }

  &-active {
    border: none;
    text-decoration: none;
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--akanthas-cyan);
    color: white;
  }
}

.next{
  &-inactive {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--silver);
    color: white;
  }

  &-active {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    background-color: var(--akanthas-cyan);
    color: white;
  }
}

.round {
  border-radius: 50%;
}

.operation-button{
  border: none !important;
  width: 5%;
  padding: inherit;
}

.card-header .button-action{
  font-size: 20px;
} 

.custom{

  &-control {
    z-index: 0 !important;
  }

  &-switch &-control-label::before {
    left: -2.6rem !important;
    width: 3rem !important;
    border-radius: 1.5rem !important;
    cursor: pointer;
  }

  &-control-label::before {
    top: 0rem !important;
    height: 1.5rem !important; 
  }

  &-switch &-control-label::after {
    top: calc(0.15rem) !important;
    left: calc(-3.5rem + 18px) !important;
    width: calc(1.2rem) !important;
    height: calc(1.2rem) !important;
    border-radius: 5rem !important;
 }

  &-switch &-control-input:checked ~ &-control-label::after {
    transform: translateX(1.3em) !important;
  }

  &-control-input:checked ~ &-control-label::before {
    border-color: var(--akanthas-cyan) !important;
    background-color: var(--akanthas-cyan) !important;
  }

}

.download-alert button{
  padding: 7px 10px !important;
}

.button-map-list {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}