
.close-modal {
  font-size: 20px;
  cursor: pointer;
  color: var(--secondary);
  margin: auto 0;
}


.modal.show .modal-dialog{
	max-width: 98.5%;
}

.download-form{
  border-bottom: none;

}

.modal-content-view > .modal-content {
  min-width: 500px;
  min-height: 95vh;
}


.modal{

  &-nav-item {
    margin-top: 10px;
  }

  &-content-title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }

  &-title-download{
    font-weight: 600 !important;
    font-size: 28px !important;
    & span{
      color: var(--blush);
    }
  }

  &-alert{
    padding: 2px !important;
    margin: auto !important;
    width: 35%;
    z-index: 1;

    font-size: 16px;

    &-content{
      top: 40px;
      width: 100%;
      position: absolute;

      &-form{
        top: 40px;
        width: 100%;
        position: sticky;
        z-index: 1;
        left: 10px;

        & .close{
          padding: 0 !important;
        }
      }
      
    }

    &-form{
      background-color: #6bff161c  !important;
      border-color:var(--status-green) !important;
      color: var(--status-green) !important;
    }

    &-red{
      background-color: #FF16171C  !important;
      border-color:var(--status-red) !important;
      color: var(--status-red) !important;
    }

    &-orange{
      background-color: #ffbd5929 !important;
      border-color:var(--status-orange) !important;
      color: var(--status-orange) !important;

    }

    & .close{
      position: relative !important;
      &:hover{
        color: inherit;
      }
    }
  }

  &-header{
    padding: 15px 10px !important;
  }

  &-header > .modal-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &-content-view .modal-content{
    background-color: var(--white-modal) !important;
    border-radius: 12px !important;
    padding: 15px;
  }

  &-item-key{
    display: flex;
    justify-content:space-between ;
    background-color: #FDFDFD;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;

    &-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 5px 12px;
      border-right: solid 1px var(--silver);
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;

      &:last-child{
        border-right: none;
      }
    }

    & i, &-value .fa-info-circle{
      margin:auto 0;
      color:var(--grey);
      cursor: pointer;

      &:hover{
        color:var(--akanthas-yellow);
      }
    }

    &-title{
      display: flex;
      justify-content: space-between;

      

      & span{
        font-size: 18px;
      }
    }

    &-value{
      display: flex;
      justify-content: space-between;

      & div{
        display: flex;
        flex-direction: column;
        color: var(--gray);
        font-size: 13px;
        justify-content: flex-end;
      }

      &  h5{
        display: flex;
        margin: auto 0;
        font-weight: 600;
        color: var(--blush);

        &> p{
          color: var(--gray);
        }

        & > span, & > p {
          font-size: 15px;
          margin: auto 2px;
          margin-bottom: 2px;
        }
      }

      &-h5-blue{
        color: var(--akanthas-cyan) !important;
      }

      &-details{
        width: 100%;
        justify-content: space-between;
      }

      & span {
        margin: auto 10px;
      }

    }



    &-star{
      display: flex;
      font-size: 30px;
      & .fas{
        color: var(--blush);
      }

      & .far{
        color: var(--gray);
      }
    }

    &-help{
      position: absolute;
      background-color: var(--akanthas-yellow);
      top: -44px;
      left: 0px;
      right: 55px;
      padding: 10px;
      font-size: 12px;
      color: var(--white);
      border-radius: 3px;

      & ul {
        margin: auto;
        padding: 8px;

        & li{
          list-style: disc;
        }
      }
    }

    &-icon{
      font-size: 55px;
      color:var(--gainsboro);
      margin: auto 10px;
    }
  }

  &-item-container{
    display: flex;
    margin-top: 20px;

    &-1-content-4{
      width: 40% !important;
      justify-content: inherit !important;
    }

    &-3-content-4{
      width: 60% !important;
      justify-content: initial !important;
    }

    &-map{
      margin: 10px;
    }

    &-details{
      display: flex;

      & div{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & span{
        margin:5px  auto;
        width: 50%;
      }
    }

    &-footer{
      display: flex;
      justify-content: space-evenly;
      margin: auto;

      & .button-submit {
        margin: auto 10px;
      }
    }

    &-content{
      display: flex;
      flex-direction: column;
      width: 50%;
      background-color: var(--white);
      margin: 30px 10px;
      border-radius: 10px;
      margin-top: 0;

      &:last-child{
        margin-right: 0;
      }

      &:first-child{
        margin-left: 0;
      }
    }



    &-title{
      display: flex;
      justify-content: space-between;
      margin: 10px 15px;
      position: relative;


      & p{
        display: flex;
        margin: 0;
      }
    }

    &-title-column{
      margin: 5px 10px;

      & div{
        display: flex;
        margin: 0;
      }
    }

    &-quality-current span{
      color:var(--akanthas-cyan);
      font-size: 16px;
    }

    &-chart-type{
      width: 40%;
      font-size: 15px;
      margin-top: 15px;

      & span{
        margin-top: auto;
        margin-left: 8px;
        padding: 0px 10px;
        background-color: var(--gainsboro);
        border-radius: 3px;
        color: var(--akanthas-cyan);
        cursor: pointer;

        &:hover{
          background-color: var(--akanthas-cyan);
          color:var(--silver) ;
        }
      }

      &-select{
        background-color: inherit !important;
        color: var(--akanthas-cyan) !important;
        border-bottom: solid;
        border-radius: 0px !important;
      }
    }
  }

  &-item-container-material{
    display: flex;
    background-color: white;
    border-radius: 10px;
  }

  &-item-chart{
    margin: auto 20px;
    height: 420px;
    justify-content: center;
    display: flex;

    &-button{
      display: flex;
      justify-content: center;

      & .button-submit{
        margin: auto 5px;
      }
    }

    &-no-data{
      margin:auto;
    }

    &-select{

      & label, & select{
        width: 50%;
      }
    }


    &-pie{
      position: relative;
    }
  }

}

.visual{
  &-container{
    display: flex;
    flex-direction: column;
  }

  &-card{
    display: flex;
    width: 100%;
    margin: 20px auto;

    &-box-image > div{
      display: flex;
      justify-content: center;
      margin: auto;
    }

    &-landscape{
      flex-direction: column;

      & .visual-data-anomaly-tooltip{
        flex-wrap: wrap;
        flex-direction: initial;
        width: 100% !important;
      }

      & .visual-data-anomaly-type{
        width: 50%;
      }
    }

    &-navigation{
      width: 70%;
    }

    &-box-image{
      justify-content: center;

      & canvas{
        position: relative !important;
      }
    }

    &-data{
      flex-direction: column;
      width: 50%;

      &-1-4{
        width: 40% !important;
      }

      &-3-4{
        width: 60% !important;
        margin-left: 10px !important;
      }

      &-landscape{
        width: 100% !important;
        flex-wrap: wrap;
        flex-direction: initial;
        margin: 0px !important;
        
        & > article{
          width: 50% !important;
        }
      }

      & > article{
        margin: 5px 0px;
        width: 75%;
      }

    }

    & article {
      display: flex;
    }

    & p{
      margin: auto;
    }
  }

  &-level{
    display: flex;
    flex-direction: column-reverse;
    border-radius: 5px;
    border: solid 2px var(--grey);
    height: 45px;
    width: 27px;
  }

  &-level-tooltip{
    display: flex;
    flex-direction: column-reverse;
    border-radius: 3px;
    border: solid 2px var(--grey);
    height: 35px;
    width: 20px;
  }

  &-data{
    margin: auto 5px;
    color: var(--grey);

    &-hidden{
      color: #9095a469;
    }

    &-anomaly{
      display: flex;
      margin: 10px auto;

      &-tooltip{
        flex-direction: column;
      }

      &-text{
        display: flex;
      }

      &-type{
        display: flex;
        margin: 2px 0;
        & p{
          margin: auto 5px;
        }
      }
    }

    & .data-icon{
      font-size: 23px;
      margin: 0;
    }

    & h5{
      font-size: 18px;
      margin: auto;
    }

    & span{
      font-size: 20px;
      font-weight: bold;
    }

    &-tooltip{
      width: 90% !important;

      & .data-icon{
        font-size: 21px;
      }

      & h5{
        font-size: 15px;
      }
    }
  }

  &-type-anomaly{
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    & .visual-data-anomaly-type{
      display: flex;
      width: 50%;
      margin: 2px 0; 
      color: var(--grey)
   }
  }
}


.container-modal{
  &-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &-nav{
    display: flex;
    margin: 2px 0;
    justify-content: space-between;

    & > div{
      display: flex;
      flex-wrap: wrap;
      margin: auto 0;
    }

    & h1> span{
      text-transform: capitalize;
    }

    & h4{
      font-size: 1.3em;
    }

    & > div > span{
      cursor: pointer;
      margin: 15px 5px;
      padding: 5px 35px;
      border-radius: 5px;
      color: var(--akanthas-cyan);
      background-color: var(--gainsboro);
      font-weight: normal;
      &:hover{
        background-color: var(--akanthas-cyan);
        color:var(--silver);
      }
    }

    & > &-search{
      width: 40%;
      z-index: 3;
    }

    &-search{
      width: 100%;
      position: relative;

      .fa-trash{
        position: absolute;
        top: 12px;
        right: 8px;
        font-size: 18px;
        color: var(--grey);
        cursor: pointer;
      }


    }
  }



  &-select{
    color: var(--akanthas-cyan) !important;
    background-color: inherit !important;
    border-bottom: solid;
    border-radius: 0px !important;
  }
}

.modal-confirmation {
  text-align: center;
  & .fa-thumbs-up{
    font-size: 100px;
    color: var(--teal);
  }
  & .fa-pencil-alt{
    font-size: 50px;
    color: var(--gray);
  }
}

.modal-information{
  text-align: right;
  color: var(--gray);
}

.help-viewer{
  &-file {
    display: flex;
    justify-content: center;
  }
  &-page{
    border: solid;
    min-width: 1020px;
  }
  &-nav {
    display: flex;
    justify-content: center;
    margin: 10px auto;

    & > button {
      background: var(--akanthas-cyan);
      border: none;
      border-radius: 22px;
      margin: auto 7px;
      color: var(--white);
      padding: 8px 12px;
    }

    & > p {
      margin: auto 0;
    }
  }
}

.flex-end{
  display: flex;
  justify-content: flex-end;
}

.font-size-35{
  font-size: 35px;
}

.font-size-50{
  font-size: 50px;
}

.m-auto{
  margin: auto;
}

.w-50{
  width: 50%;
}

.justify-initial{
  justify-content: initial !important;
}
