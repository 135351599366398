.wasteBox {
  font-size: 22px;
  font-weight: bold;
  color: blue;
}

.addressBox {
  vertical-align: 3px;
  font-size: 13px;
  font-weight: bold;
  color: grey;
  background-color: transparent;
  border: 2px solid #ccc;
  border-radius: 15px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: inline-block;
}

.icon-line {
  display: flex;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ddd;
}

.stars {
  display: flex;
  align-items: center;
}

.star {
  color: #888;
  margin-right: 5px;
}

.text {
  font-size: 15px;
  color: #888;
}

.progress-bar {
  position: relative;
  width: 18px;
  height: 22px;
  border-radius: 4px;
  border: 2px solid grey;
  background-color: transparent;
}

.progress-filling {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--status-grey);
  transition: height 0.3s ease;
}

.mass-icon {
  display: flex;
  align-items: center;
}

.mass {
  font-size: 22px;
  margin-right: 5px;
  height: 22px;
  color: grey;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.disabled {
  cursor: not-allowed;
  background-color: grey;
}



  