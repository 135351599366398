@tailwind components;
@tailwind utilities;


:root {
    --akanthas-cyan: #00A7A6;
    --akanthas-cyan-transparent: #00A7A78F;
    --akanthas-blue: #0F265C;
    --akanthas-red: #E5193C;
    --akanthas-yellow: #f8AA00;
    --dark-blue: #4258A1;
    --dark-cyan: #005A72;
    --dark-grey: #494C6E;
    --grey: #828899;
    --grey-input: #495057;
    --blush: #E81D7A;
    --green: #44b830;
    --light-cyan: #0B9C98;
    --light-green: #29CC97;
    --light-grey: #F5F5F5;
    --gainsboro: #DCDCDC;
    --gainsboro-transparent: #DCDCDC8A;
    --white-modal: #EFEFEFFB;
    --white-transparent: #F5FFFF;
    --orange: #ED9624;
    --silver: #D0D0D0;
    --status-blue: #38B6FF;
    --status-green: #7ED958;
    --status-grey: #A6A6A6;
    --status-pink: #FF66C4;
    --status-orange: #FFBD59;
    --status-red: #FF1617;
    --status-red-danger: #AC0001;
}


.akanthas-cyan{
    color: var(--akanthas-cyan);
}

.akanthas-blue{
    color: var(--akanthas-blue);
}