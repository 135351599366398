input, select {
  width: 100%;
  min-width: 60px;
  height: 35px;
  border: 0px solid var(--silver);
  border-radius: 4px;
}

.input-group{
	flex-direction: row !important;
}

.operation-sort{
  padding-left: 10px;
  background-color: var(--silver);
  color: var(--dark);
}

.information-bloc-column {
	display: flex;
	width: 60%;
	justify-content: space-between;
	margin: 5px auto;
}

.information-data-multiple{
	margin: auto;
	color: var(--grey);
	
	&:first-child{
		margin-left: 0px;
	}

	&:last-child{
		margin-right: 0px;
	}
}

.information-bloc-column > input, .information-bloc-column > select,.information-percenatge {
	// width: 48%;
	margin: auto 5px;





}

.information-column {
	margin: auto !important;
	width: 35%;
}

.information-column>input{
	width: 100%;
}

.information-column.data-block{
	width: auto !important;
}

.information-bloc {
	width: 95%;
	margin: auto;

	& .search-wrapper{
    background-color: var(--white);
	}
}

.information-bloc  > h3 {
	border-bottom: solid 1px;
	width: 100%;
	margin: 15px auto;
}

.information-line {
	display: flex;
	width: 100%;
	margin: 20px auto;
	justify-content: space-between;
	position:relative;

	& > div{
		display: flex;
		width: 60%;
		flex-direction: column;
	}

}

.information-label {
	width: 40%;
}

.information-percenatge{
	display: flex;
}

.information-percenatge>span{
	margin: auto 0px auto 5px;
}

.information-select{
	color: #495057;
	background-color: var(--white);
	border: 1px solid #ced4da;
	width: 60%;
}

.information-line select{
  padding: 0 !important;
}

.information-footer-modal {
	justify-content: center !important;
}


.information-column .object-fit-cover {
	max-width: 200px;
	max-height: 160px;
}

.change-password{
	display: flex;
	justify-content: space-between;
	h3{
		width: 100%;
	}
}

.operation-soon{
	color:var(--akanthas-red) !important;
}

.dropdown{
  position: relative;
  z-index: 999 !important;
}

.error-form {
	text-align: center;
	background-color: var(--akanthas-yellow);
	width: 70%;
	border-radius: 5px;
	color: var(--white);
	display: none;
	margin: 5px auto;
	&>p{
		margin: auto;
	}
}

.display-error{
	display: block;
}

.container{
	&-header-content{
		display: flex;
		justify-content:space-between;
	}

	&-header-title{
		margin: auto 0;
		width: 30%;
	}

	&-header-filter{
		display: flex;
		width: 70%;
	}
	&-header-filter > label{
		width: 45%;
		margin: auto 0;
		font-size: 13px;
		text-align: end;
	}
	&-header-filter > select{
		width: 55%;
		margin: auto 10px;
		font-size: 0.85em;
	}
}

