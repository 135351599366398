.leaflet-popup {
  transform: none !important;
  position: absolute !important;
  top: calc(100vh / 4) !important;
  left: 10px !important;
  bottom: 0px !important;
  right: 0% !important;
  width: 300px;
  background-color: white;
  border-radius: 10px;
}

.leaflet-popup-close-button span {
  color: white !important;
}

.map-popup .popup-header {
  border-radius: 10px 10px 0px 0px;
  background-color: #2b9992;
  margin-left: -1px;
  height: 50px;
  margin-top: -14px;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 5px;
}

.map-popup .indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
}

.map-popup .indicator .indicator-overflow {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.map-popup .popup-header .header-text {
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center !important;
  margin-top: 20px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ajustez ce nombre en fonction du nombre de lignes souhaité */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.leaflet-popup-tip-container {
  display: none !important;
}

.leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 13px 24px auto auto;
}

.leaflet-container .leaflet-popup-content-wrapper {
  position: absolute;
  left: 0 !important;
  height: 450px;
  width: 300px;
  top: -1px;
}

.map-popup-image {
  padding: 10px 10px 10px 20px !important;
}

.dashboard-text-underline {
  margin-right: 10px !important;
}
