.container-modal-nav-search .search-wrapper {
	background-color: var(--white);
}

.search-wrapper{
	padding: 3px !important;
	display: flex;
	background: var(--silver);
	overflow:auto;
	height: 38px;
	border-radius: 4px
}

.searchBox {
	height: auto;
}

.chip {
	background-color: var(--akanthas-cyan) !important;
	border-radius: 3px !important;
	padding: 4px 20px 4px 10px !important;
}

.searchBox,.chip{
	margin: 2px 3px;
}

.highlight {
	background: inherit;
	color: black;
}

.option:hover {
	background-color: var(--akanthas-cyan) !important;
}

.optionContainer > .option{
  padding: 10px 2px;
}

.optionContainer > .option > .checkbox {
  width: 10px;
  height: 15px; 
  margin-right: 0; 
  padding: 2px;
}

.optionListContainer{
	z-index: 3 !important;
}

.dropdown-materials {
	min-width: 280px;
}
.dropdown-materials .chip{
	display: none;
} 