
.login-form input {
  border-radius: 5px;
  border: 2px solid #dadada;
  width: 100%;
  padding: 10px;
  font-size: 15px;
}

.signin {
  width: 100%;
  margin: auto;
  text-align: center;

  &-input-password {
    position: relative;
    width: 100%;
  }

  &-icon-eye {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  &-error-message {
    text-align: center;
    margin: 9px auto;
  }

  > img {
    margin: auto;
  }
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;

  .login-container {
    width: 100%;
    max-width: 450px;
    padding: 20px;

    .login-box {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 25px;
      text-align: center;

      .login-logo {
        width: 100px;
        margin-bottom: 15px;
      }

      .login-form {
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          height: 45px;
          padding: 12px;
          margin-bottom: 8px;
          border: 1px solid rgba(130, 136, 153, 0.5);
          border-radius: 7px;
          background-color: #fff;
          color: #333;
          font-size: 13px;

          &:focus {
            border-color: #000;
            outline: none;
          }

          &:valid {
            background-color: #f0f0f0;
          }
        }

        .password-field {
          position: relative;
        }

        .forgot-password {
          margin-top: 10px;
          margin-bottom: 20px;
          color: var(--akanthas-cyan);
          text-decoration: none;
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        }

        .login-button {
          width: 100%;
          background-color: var(--akanthas-cyan);
          color: white;
          padding: 12px;
          border: none;
          border-radius: 7px;
          font-weight: bold;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease, transform 0.3s ease;

          &:hover {
            background-color: var(--akanthas-yellow);
          }
        }

        .button-disabled {
          background-color: #ccc;
          color: #666;
          cursor: not-allowed;
        }

        .divider {
          display: flex;
          align-items: center;
          margin: 20px 0;
          font-size: 14px;

          &:before,
          &:after {
            content: "";
            flex: 1;
            height: 1px;
            background: #ddd;
          }

          span {
            margin: 0 10px;
            color: #888;
          }
        }
      }
    }
  }

  .header {
    position: absolute;
    top: 30px;
    left: 30px;

    .logo {
      width: 160px;
    }
  }

  @media only screen and (max-width: 768px) {
    .header {
      position: absolute;
      top: 30px; /* Toujours en haut, mais centré */
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 768px) {
    .login-box {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.sign-in-message {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
}

.tagline {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: left;
}

.back-link {
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: var(--akanthas-cyan);
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  text-decoration: none;
}

.back-link:hover {
  color: var(--akanthas-yellow);
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 15px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 100%;
}

.footer-item {
  color: #828899;
  font-size: 10px;
  font-weight: 500;
  margin: 0 10px;
}

.copyright-logo,
.footer-link,
.language-select {
  font-size: 12px;
}

.footer-link {
  text-decoration: none;
  color: #828899;
  cursor: pointer;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-link:link,
.footer-link:visited,
.footer-link:hover,
.footer-link:active {
  color: #828899;
}

.language-select {
  cursor: pointer;
}

.language-arrow {
  margin-left: 5px;
  font-size: 10px;
  font-weight: normal;
}

.language-dropdown {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 5px;
}

.language-option {
  margin: 0 5px;
  cursor: pointer;
  color: var(--akanthas-blue);
}

.language-option:hover {
  text-decoration: underline;
}

.error-message {
  content: '⚠';
  margin-right: 5px;
  color: var(--akanthas-red);
  font-size: 12px;
  font-weight: 550;
  margin-top: 0px;
  text-align: left;
}

.success-message {
  color: green;
  font-size: 12px;
  font-weight: 550;
  margin-top: 0px;
  text-align: left;
}

.global-error {
  margin-top: 10px;
  color: var(--akanthas-red);
}

.toggle-password-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  font-size: 12px;
  font-weight: 550;
  cursor: pointer;
  background-color: transparent;
  border: none;
}