.floating-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--akanthas-yellow);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  max-width: 350px;
  z-index: 1000;
  opacity: 0.3; /* Transparence par défaut */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.floating-toggle:hover {
  opacity: 1; /* Pleine opacité au survol */
}

.toggle-content {
  padding-right: 40px
}

.toggle-content strong {
  color: black;
  font-weight: bold;
}

.toggle-content p {
  margin: 5px 0;
  font-size: 14px;
  color: black;
}

.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.switch.client {
  background-color: var(--akanthas-blue);
}

.switch.admin {
  background-color: var(--akanthas-blue);
}

.switch .knob {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;
  left: 5px;
}

.switch.admin .knob {
  left: 25px;
}