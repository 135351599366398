/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@1000&family=Secular+One&display=swap");

/* GLOBAL */
body {
  font-family: "Mulish", sans-serif;
  background-color: var(--light-grey) !important;
  overflow-x: hidden;
}

#root {
  min-width: 100vw;
}

/* App */
.App {
  display: flex;
  min-width: 100vw;
}
.app-container {
  width: auto;
}
button {
  cursor: pointer;
}

a {
  color: var(--akanthas-blue) !important;
}
