.checkbox-main {
  height: 15px;
  width: 15px;
  text-align: center;
  vertical-align: middle;
	margin: auto;
	min-width: inherit;
}

.select-many {
	height: 16px;
	margin: auto 10px;
	width: 11% !important;
  min-width: inherit;
}

.checkbox-change-password.form-check-input {
	position: relative !important;
	height: 20px;
	margin: auto;
	width: 0%;
}

.information-data-check {
	position: relative !important;
	margin: auto 0;
	width: auto;
	height: 18px;
	background-color: var(--akanthas-cyan);
}

.information-data-check:checked  {
	background-color: var(--akanthas-cyan);
}

.checkbox{


	&-ios-label {
		position: relative;
		display: inline-block;
		border-radius: 3em;
		background-color: rgba(0, 0, 0, 0.15);
	}

	&-ios-input {
		position: absolute;
	}

}

.form-radio-button{
	display: flex;
	margin: auto 5px;

	& label {
		margin: auto 5px;
	}
	& input{
		cursor: pointer;
		min-width: 20px;
		width: auto;
	}
}

@media (max-width: 1250px) {
	.checkbox-main{
		margin: 3px auto;
	}
}

