@media (max-width: 1250px) {

	.data-action{
		// height: 0%;
		&, .column-action{
			// top:10px;
		}
	}

	.column-address, .data-address {
		width: 18%;
	}

	.card-block-display{
		width: 50%;
	}

	.time-line-title, .time-line-data,.time-line-title-large, .time-line-data-large {
		width: 15% !important;
	}

	.time-line-data .level-content{
		width: 70%;
	}

	.time-line-data.data-action{
		width: 18% !important;
		margin: auto 2px;
	}

	.visual-data-tooltip {
		width: auto !important;
	}



}

.help {

	margin: 30px auto;

	&-use > h3 {
		font-size: 20px;
		font-weight: 600;
	}

	&-block {
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 20%;
		background: var(--white);
		border-radius: 10px;
		margin: 10px;
		cursor: pointer;
	}
	&-list {
		display: flex;
		justify-content: center;
	}

	&-icon {
		font-size: 50px;
		margin: 20px;
		color: var(--akanthas-cyan);
	}


}


@media (max-width: 1175px) {
	.table-block, .content-table-block {
		width: 100%;
		margin: 8px 0px;
	}

	.table-filter {
		width: 46%;
	}

	.card-block-display{
		display: none;
	}

	.table-block-card{
		width: 100%;
		margin: 5px auto;
	}

	.top{
		&-filter {
			width: 95%;
			margin: auto;
		}

		&-table{
			flex-direction: column;
		}

	}

	.operation-table {
		justify-content: center;
		width: 100%;
		margin: 0 auto;

	}

	.table-bottom-wrapper {
		width: 100%;
		justify-content: center;
	}

	.base>h1{
		width: 95%;
		margin: auto;
	}

	.table{
		&-block{
			display: flex;
			background-color: #FFF;
			border-radius: 6px;
			min-height: 200px;
		}

		&-column{
			display:none;
		}

		&-line{
			height: 275px;
			background-color: inherit;
			flex-direction: column;
		}
	}

	.data{
		&-not-display-responsive{
			display: none;
		}

		&-image-block{
			display: block;
			margin: auto;

		}
		

		&-table{
			width: 100%;
			margin: auto 0;

			&:first-child{
				font-weight: 600;
			}

			&-display{
				display: flex;
				flex-direction: column;
			}
		}

		&-double{
			width: 100%;
			flex-direction: column;
		}

		&-width-up{
			width: 98% !important;
		}
	
	}

	.data-action, .column-action{
		width: 35% !important;
	}

	.circle{
		width: 0%;
	}

	.object-fit-cover{
		width: 110px;
	}

	.level{
		width: 10%;
	}

	.level-content{
		width: 90%;
	}

	.data-double .data-table{
		margin: auto 0;
		width: 100% !important;
	}

	.time-line-data {
		width: 13%;
	}
}

@media (max-width: 1120px) {

	.time-line{

		&-col{
			display: none;
		}

		&-item{
			flex-direction: column;
		}
	
		&-data{
			&-bloc{
				display: flex;
				flex-direction: column;
				&-hide{
					display: none;
				}

				&-row{
					display: flex;
					margin: 0 3px;

					& .level{
						color: var(--grey);
					}
				}
			}

			& .data-icon{
				font-size: 25px;
			}

			&.data-action {
				margin: auto 6px;
			}

		}

		&-column-data {
			flex-direction: row;

			& span {
				margin: 0 5px;
			}

			&:first-child{
				margin-left: 0;
			}
		}

		&-data, &-data-large {
			width: 100% !important;
		}
	}
}

@media (max-width: 650px) {
	.table-filter {
		width: 100%;
	}

	.time-line-data.data-action {
		margin: auto 15px;
	}

	.time-line-column-data {
		flex-direction: column;
	}

	.activity-history-nav {
		flex-direction: column;
		justify-content: center;

		& article{
			margin: 5px 10px;
			justify-content: space-between;
		}

		& label{
			width: 20%;
		}

		& input{
			width: 80%;
		}
	}

	.time-line-tooltip-title div {
		flex-direction: column;
	}


}

@media (max-width: 475px) {


	.data{
		&-checkbox {
			margin: auto 15px 0 15px !important;
			max-width: 90px;
		}

		&-action, .column-action{
			width: 80% !important;
		}
	}
}

.bottom-left-block {
	z-index: 3;
	border-radius: 10px;
	position: fixed;
	bottom: 5%;
	left: 200;
	background-color: #FFF;
	border: 1px solid #ccc;
}

.bottom-left-block-header {
	background-color: #2b9992;
	border-radius: 10px 10px 0 0;
	height: 10%;
}