.header {
  height: 60px;
  background-color: var(--light-grey);
  padding: 0 20px;
  position: relative;
  border-bottom: 0px solid #9fa2b4;
}

.header-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.header-user-infos-wrapper {
  display: flex;
  cursor: pointer;
  margin: auto 5px;
  position: relative;
  padding: 8px;
}

.header-user-info-text {
  color: white;
  padding: 15px;
  margin: 0;
  width: 90%;
  text-align: initial;
  text-transform: capitalize;

}

.header-user-close{
  margin: auto 10px;
}

.header-user-info-text2 {
  font-weight: 300;
  font-size: 12px;
}

.header-user-dialog {
  position: absolute;
  flex-direction: column;
  background-color: var(--akanthas-cyan);
  border: 2px solid var(--silver);
  border-radius: 5px;
  z-index: 9999999;
  width: 275px;
  right: 8px;
  top:50px;
  display: none;
  padding: 5px 0px;
}

.header-user-info-top {
  background: var(--akanthas-cyan);
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.visible {
  display: flex;
  }

.header-button {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: white;
  background-color: var(--akanthas-cyan);
  border: 0px;
  font-weight: 200;
}

.header-button:hover {
  background-color: var(--akanthas-yellow);
}

.logoHeader{
  height: auto;
  width: 30px;
  border-radius: 50%;
  font-size:30px;
  margin: auto 0;
}

.logoHeader:hover{
  color:var(--akanthas-yellow);
}

.user-info-list {
	border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-user-info-top > h5 {
	text-align: left;
	padding: 15px;
	background-color: var(--akanthas-cyan);
	color: var(--white) !important;
}

.header-button.bell {
	background-color: initial;
	color: var(--akanthas-cyan);
}

.header-button.help {
	background-color: initial;
	color: var(--akanthas-cyan);
  margin: 8px 3px;
	vertical-align: sub;
  font-size: 25px;
}
