

@media (max-width: 1279px) {

  .dashboard{
    &-indicator h3 {
      font-size: 30px;
    }

    &-icon-recycle {
      font-size: 50px;
      margin: auto 5px;
    }

    &-card {
      min-height: 320px;
    }
  }
}

@media (max-width: 1220px) {
  .container-modal-nav {
    flex-direction: column;
  }
}


@media (max-width: 775px) {

  .dashboard-card {
    width: 100%;
  }
}