.react-calendar { //for the react-calendar library
  width: 460px;
  max-width: 100%;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
  line-height: 0px;
  border:initial;
}

.react-calendar__navigation button { //for the react-calendar library
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.form-calendar{

  &-close{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    
    & > span{
      margin: 5px;
    }
    & > svg {
      margin: auto 0;
    }
  }
}