

@media (max-width: 1280px) {
  .modal.show .modal-dialog{
    max-width: 98%;
  }

  .modal-alert{
    width: 60%;
  }

  .visual-card-data > article {
    margin: 8px auto;
    width: 85%;
  }

  .modal-item-container-details{
    & .object-fit-cover {
      width: 185px;
    }
   }

  .modal-item{
    &-chart {
      margin: 5px 10px;
    }

    &-container-title {
      margin: 10px 14px;
      
      & p{
        font-size: 13px;
      }
    }

    &-container {
      margin-top: 0px;
    }

    &-container-chart-type {
      width: 45%;
    }

    &-chart{
      &-option > div {
        width: 60%;
      }

      &-custom-date article {
        width: 25%;
      }

      &-option section {
        justify-content: flex-end;
        width: 100%;
      }

      &-custom-date article, &-option section{
        margin: 5px 0;
      }
    }

  }

  .visual{
    &-level {
      height: 44px;
      width: 25px;
    }

    &-data{
      font-size: 15px;
    }

    &-data .data-icon {
      font-size: 22px;
    }
  }

  .information-column .object-fit-cover {
    max-width: 100px;
    max-height: 100px;
  }

}

@media (max-width: 1085px) {

  .modal{
    &-item-key-title span {
      font-size: 15px;
    }

    &-item-key-value h5 {
      font-size: 22px;
    }

    &-item-chart-select {

      & label {
        text-align: right;
        margin: auto 10px;
      }
    }

    &-item-container-content {
      margin: 10px 4px
    }

    &-item-chart-option{

      & >div {
        width: 100%;
        margin: auto 0px;
        flex-direction: column-reverse;
      }

    }

    &-item-container-chart-type span {
      margin: auto 3px;
      padding: 0px 7px;
    }

  }

  .visual{
    &-data{
      margin: auto 3px;
      font-size: 13px;
      & span {
        font-size: 16px;
      }

      & h5{
        font-size: 15px;
      }

      & .data-icon {
        font-size: 18px;
      }

      & > article{
        width: 100%;
      }
    }

    &-card{
      margin: 10px auto;
      &:first-child {
        width: 90%;
        margin: 10px auto;
      }

      &-data{

        & > article {
          margin: 5px auto;
          width: 90%;
        }

        &-landscape{
          margin: auto 12px;
          & > article {
            width: 50% !important;
          }
        }
      }
    }

    &-level {
      height: 40px;
      width: 24px;
    }
  }


}

@media (max-width: 950px) {
  .visual-card-data-3-4 {
    width: 50% !important;
  }

  .visual-card-data-1-4 {
    width: 50% !important;
  }

  .modal-alert{
    width: 80%;
  }

  .modal-item-container-material{
    flex-direction: column;
  }

  .modal-item-container-content{
    width: auto;
  }


}

@media (max-width: 775px) {

  .modal.show .modal-dialog {
    margin: 15px auto;
  }

  .container-modal-nav{
    margin: 0px;
    & span {
      padding: 2px 10px;
    }
  }

  .modal{
    &-alert{
      width: auto;
      &-content{
        top: 38px;
      }
    }

    &-title-download{
      font-size: 21px !important;
    }



    &-item-key {
      flex-wrap: wrap;
      background-color: inherit;
      &-content {
        border: inherit;
        width: 47%;
        background-color: var(--white);
        border-radius: 8px;
        margin: 5px;
        min-height: 85px;
      }
    }

    &-item-container {
      flex-direction: column;
      margin: 5px;

      &-content {
        width: 100%;
        margin: 15px auto 0 auto;
      }

      &-1-content-4 {
        width: 100% !important;
      }

      &-3-content-4 {
        width: 100% !important;

      }

      &-footer {
        display: flex;
        justify-content: space-evenly;
        margin: 15px 0;
      }
    }

    &-item-chart-pie{
      min-height: 300px;
    }

    &-item-container-chart-type {
      width: 40%;
    }
  }

  .information-column .object-fit-cover {
    max-width: 90px;
    max-height: 90px;
  }

  .modal-title>h2 {
    font-size: 1.5rem;
	}
}

@media (max-width: 485px) {
  .modal-item-key-content {
    width: 45%;
  }
}
