.filter-toggle-button {
    background-color: transparent;
    color: var(--grey);
    padding: 5px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .filter-toggle-button:hover {
    background-color: transparent;
    color: var(--grey);
  }
  
  .filter-toggle-button.active {
    background-color: transparent;
    color: var(--grey);
  }