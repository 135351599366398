.primary {
	color: var(--blush);
}

.success{
	color: var(--akanthas-cyan);
}

.secondary{
	color: var(--akanthas-blue);
}

.dashboard {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 15px auto;

	& h1 > span{
		text-transform: capitalize;
	}

	&-base{
		color:var(--akanthas-blue);
	}

	&-card {
		flex-direction: column;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		background-color: var(--white);
		margin: 0px auto;
		padding: 10px 25px;
		border-radius: 13px;
		justify-content: space-between;

		&-top {
			display: flex;
			position: relative;
			width: 100%;
			justify-content: space-between;
		}

		&-footer {
			display: flex;
			//flex-wrap: wrap;
			margin: 15px 0;
			//justify-content: flex-end;
			font-size: 14px;

			& > div {
				margin: auto 5px;
				font-size: 14px;
			}
		}

		&-content{
			& > section {
				display: flex;
				margin: 15px 0;
				height: 90%;
			}

			& .success{
				font-size: 27px;
				font-weight: 600;
			}
		}
	}

	&-indicator{
		display: flex;
		width: 100%;
		justify-content: space-between;

		

		&-center{
			display: flex;
			flex-direction: column;
			text-align: center;
			border-right: 1px solid;
			width: 50%;

			&:last-of-type{
				border:none;
			}
		}

		& h3 {
			font-size: 35px;
			font-weight: 600;
		}

		& h6{
			font-size: 18px;
			color:var(--gray);
		}

		& > div{
			position: relative;
		}

		& > article{
			display: flex;
		}
	}
	
	&-chart{
	
		width: 100%;
		max-width: 100%;

		&-column{
			margin: 0 8px;
			width: 9%;
		}

		&-doughnut{
			display: flex;
			justify-content: center;
			margin: auto;
			width: 75%;
		}
	}

	&-filling{

		display: flex;
		flex-direction: column;
		
		&-delivery {
			display: flex;
			justify-content: flex-start;
			margin: 5px;
		}

		&-color {
			padding: 10px;
			background: blue;
			margin: 4px 10px;
			margin-left: 0px;
			border-radius: 50%;
		}

		&-type {
			color: var(--gray);
			margin: 0 10px;
		}

	}

	&-icon{
		&-recycle{
			font-size: 60px;
			color: var(--akanthas-cyan);
			margin: auto 20px;			
		}

		&-cloud{
			font-size: 150px;
			color: var(--gainsboro);
		}

		&-text{
			position: absolute;
			font-size: 50px;
		}
	}

	&-ticket{

		&-wrap{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

	}

	&-text-underline{
		text-decoration: underline;
	}


}

.address-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
  }

.address-button {
	background-color: transparent;
	border: 1px solid #ddd;
	border-radius: 12px;
	margin-bottom: 8px;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	transition: background-color 0.3s ease;
}

.address-button:hover {
	background-color: #f5f5f5;
}

.address-text-container {
	display: flex;
	align-items: center;
}

.address-text {
	flex: 1;
	font-size: 14px;
}

.info-container {
	display: flex;
	align-items: center;
}

.container-count {
	margin-left: 8px;
	font-size: 14px;
}

.dropdown-sortable {
	position: relative;
	display: inline-block;
	margin-bottom: 4px;
}

.dropdown-content {
	position: absolute;
	background-color: #f9f9f9;
	border: 1px solid #ccc;
	border-radius: 12px;
	padding: 10px;
	width: 300px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	z-index: 1000;
}

.sortable-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.sortable-item {
	display: flex;
	align-items: center;
	padding-left: 12px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background: #fff;
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.sortable-item:hover {
	background-color: #f1f1f1;
}

.drag-icon {
	margin-right: 10px;
	cursor: grab;
	font-size: 16px;
	color: #666;
}

.sortable-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.sortable-name {
	margin-left: 8px;
	margin-top: 8px;
}

.sortable-checkbox {
	margin-left: 8px;
	margin-top: 8px;
	width: 16px;
	height: 16px;
}

.config-button {
	display: flex;
	align-items: center;
	gap: 8px;
	background-color: #ffffff;
	color: #333333;
	border-radius: 12px;
	padding: 8px 12px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	transition: all 0.3s ease;
}

.config-button:hover {
	background-color: #f4f4f4;
}

.config-icon {
	font-size: 16px;
	color: #666666;
}

.config-button:active {
	background-color: #e9e9e9;
}

.btn-validate {
	background-color: var(--akanthas-cyan); /* Vert */
	color: white;
	margin-top: 10px;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}