

@media (max-width: 950px) {
	.information-label {
		width: 25%;
	}

	.input-group {
		height: 0;
	}
}

@media (max-width: 650px) {
	.information-line {
		flex-direction: column;
	}

	.information-label {
		width: 100%;
	}

	.input-group {
		height: auto;
	}

	.information-line.information-line-active.form-group {
		flex-direction: row;
	}

}

@media (max-width: 475px) {
	.information-column {
		margin: auto 3px !important;
	}
}
