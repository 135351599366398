
.card .card-header {
  background-color: white;
}

.card.table {
	background-color: inherit !important;
	border: inherit !important;
  position: inherit !important;
}

.card-header {
  text-transform: uppercase;
}

.card.card-filter {
	background-color: inherit !important;
	margin-bottom: 10px;
	border: none;
}

.card-block{
	position: sticky !important;
	width: 100%;
	top: 0px;
	background-color: var(--white);
  border-radius: 5px;
  max-height: 100vh;
  overflow:hidden;

  &-display{
    width: 65%;
    position:relative;
		margin: 10px auto;

  }
  &-header, &-header-history{
    justify-content: space-between;
    padding: 10px;
    border-bottom: solid #F5F5F5;
    display: flex;
    background-color: var(--white);
    border-radius: 8px;
  }
  &-header-history {
    position: sticky;
    z-index: 2;
    top: 0;
  }

  &-filter{
    display:none;
    &-display{
      display: flex;
    }
  }
}

.time-line{
  margin:20px 0px;
  position: relative;

  &-column-data{
    color: var(--gray);
  }

  &-element{
    position: relative;
  }

  &-column-data{
    display: flex;
    font-size: 14px;
    margin: auto 5px;
    flex-direction: column;
  }

  &-data{
    display: flex;
    font-size: 14px;
    margin: auto 5px;
    color: var(--grey);
    overflow: hidden;

    & &-large{
      flex-direction: row !important;
      color: var(--gray);
    }

    & .button-action{
      font-size: 20px;
    }

    &-bloc, &-bloc-row{
      display: none;      
    }

    &-bold{
      font-weight: 600;
      color: var(--dark);
    }
  }

  &-title{
    margin: auto 5px;
    font-size: 14px;
    font-weight: 600;
  }

  &-title, &-data{
    width: 10% !important;
  }

  &-title-medium, &-data-medium{
    width: 15% !important;
  }

  &-col{
    padding: 15px 0;
    background-color: var(--white);
    border-radius: 5px;  
    position: sticky;
    top: 62px;
    z-index: 1;
    box-shadow: 0px 0px 6px -4px var(--dark);
  }

  &-col, &-line{
    justify-content: space-between;
  }

  &-title-large, &-data-large{
    width: 20% !important;
  }

  &-item{
    position: relative;
    display: flex;
    margin: 5px auto;
    padding: 12px 0px;
    background-color: var(--white);
    border-radius: 5px;

    &:hover{
      background-color: #F5FFFF;
    }
  }


  &-error{
    margin: 5px auto;
    width: 50%;
    text-align: center;
    color: var(--akanthas-red);
    border: solid 1px;
    border-radius: 2px;
  }

  &-tooltip{
  
    position: absolute;
    z-index: 3;
    top: -200px;
    right: 65px;
    background-color: var(--white);
    padding: 15px;
    box-shadow: 0px 0px 10px -3px var(--dark);
    border-radius: 6px;
    width: 500px;
    
    &-title {

      display: flex;
      justify-content: space-between;

      & div {
        display: flex;
        margin: auto 5px;
      }

      & h6 {
        font-size: 16px;
        margin: auto 2px;
        font-weight: 600;
        color:var(--grey)
      }
    }

  }

  &-filling-collection{

    width: 80%;
    padding: 2px 18px;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: var(--white);
    position: relative;

  
    & .button-action {
      width: auto;
      margin: auto 2px;
      font-size: 18px;
      color: var(--white);

      &:hover{
        color: var(--akanthas-yellow);
      }
    }

    & span{
      margin: auto 2px;
    }
  }
}

.popover.show.bs-popover-auto {
	left: -50px !important;
}

.test-page{
  background-color: red;
}