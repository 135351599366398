nav {
  /* width: 250px; */
  /* width: 250px; *
  background-color: rgba(54, 55, 64, 0.8); */
  height: 100%;
}

.nav {
  position: fixed;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}

.navItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  height: 40px;
  width: 120%;
  line-height: 40px;
  text-decoration: none;
}

.navItem:hover {
  /* border-left: 0.5px solid white; */
  background-color: rgba(1,1,1,0.2);
}

.navbarItem > .active > a {
  background-color: rgba(1,1,1,0.2);
}

.selected {
  background-color: rgba(1,1,1,0.2);
}

.nav-icon {
  margin-top: -3px;
  margin-left: 8px;
  margin-right: 10px;
  color: #a4a6b3;
}

.nav-text {
  text-transform: uppercase;
  color: #a4a6b3;
}

.nav-logo-container {
  display: flex;
  margin-top: 20px;
}

.nav-logo {
  margin-bottom: 15px;
  margin-left: -8px;
  height: 65px;
  width: 150px;
}

a {
  text-decoration: none !important;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL{
  min-width: 220px;
}