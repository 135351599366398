.blue-background {
  background-color: var(--status-blue);
}

.green-background {
  background-color: var(--status-green);
}

.grey-background {
  background-color: var(--status-grey);
}

.orange-background {
  background-color: var(--status-orange);
}

.red-background {
  background-color: var(--status-red);
}

.pink-background {
  background-color: var(--status-pink);
}

.circle-content{
  padding: 13px;
  border-radius: 100%;
  height: 25px;
  margin: auto 0;
}

.level-content {
  width: 60%;
  border: solid 1px var(--silver);
  border-radius: 15px;
  padding: 0px 0px;
  height: 8px;
  margin: auto 0px;
  background-color: var(--silver);
}

.level-padding{
  height: 100%;
  border-radius: 15px 0 0 15px;
}

.level {
  margin: auto 2px;
  width: 15%;
  white-space: nowrap;
}

.level-blue{
  background-color: var(--akanthas-blue);
}

.level-red{
  background-color: var(--status-red);
}

.level-orange{
  background-color: var(--status-orange);
}

.level-green{
  background-color: var(--status-green);
}

.action-choice {
  display: none;
  width: 100%;
  margin: auto;
  background: var(--akanthas-cyan);
  border-radius: 5px;
}

.action {
  border: none;
  background-color: initial;
  color: var(--white);
  text-align: center;
  margin: auto;
  padding: 0px 25px;
  width: 100%;
}

.action:hover{
  background-color: var(--light-cyan);
  margin: auto;
  border-radius: 5px;
}

.display-action {
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.container-title {
  display: flex;
  align-items: center;
}

.container-title > h1 {
  font-size: 32px;
  font-weight: 400;
  color: var(--akanthas-blue);
}

.ico-user {
  color: var(--akanthas-cyan);
}

.filling-level{
  flex-direction: row !important;
}

.no-data {
  margin: auto;
  text-align: center;
  padding: 15px;
  color: var(--akanthas-red);
  font-size: 20px;
}