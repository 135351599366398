.table-bottom-wrapper {
  display: flex;
  justify-content: flex-end;
}

.row-wrapper {
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  height: 90px;
}

.row-wrapper:hover {
  background-color: #f7f8ff;
}

.row-link {
  text-decoration: none;
  margin: 1rem 15px;
  margin-top: 40px;
  display: flex;
  width: 100%;
}

.row-link > div {
  padding: 10px 10px;
  color: #252733;
  font-weight: 400;
  font-size: 14px;
  flex: 1;
  text-align: left;
}

.row-link > div > p {
  margin-top: 5px;
  font-size: 10px;
  color: var(--grey);
  text-align: left;
}

.app-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.table-column {
	width: 100%;
	height: 50px;
	justify-content: space-between;
	background-color: #FFF;
	padding: 5px;
	border-radius: 6px;
	margin: 6px 0px 2px 0px;
}

.column-title {
	margin: auto 5px;
	font-size: 14px;
	font-weight: 600;
}

.content-table {
	display: flex;
	flex-direction: column;
}

.table-line {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 3px auto;
	border-radius: 6px;
	background-color: #FFF;
	padding: 8px 8px;
	min-height: 60px;
	cursor: pointer;

	&:hover{
		background-color: #F5FFFF;
	}

	&-data{
		position: relative;
		display: flex;
		width: 100%;
		margin: auto 0px;
	}

}

.data{
	&-prefix-id {
		color: var(--gray);
	}

	&-table {
		display: flex;
		flex-direction: column;
		font-size: 14px;
		margin: auto 5px;

		&-id{
			font-weight: 800;
		}

		&-display{
			font-size: 14px;
		}
	}

	&-double{
		flex-direction: row;
	}

	&-checkbox{
		min-width: 10%;
	}

	&-bold{
		font-weight: 600;
	}

	&-prevision{
		color: var(--status-red);
		font-size: 11px;
	}

}

.column-title,.data-table{
	width: 15%;
}

.data-row, .column-row{
  flex-direction: row;
	display: flex;
}

.column-double,
.data-double {
	display: flex;
	margin: auto 5px;
	width: 25%;
}

.column-double:first-child,
.data-double:first-child {
	margin: auto 5px;
}

.column {
	&-double{
		flex-direction: column;
	}

	&-double h6{
		width: 100%;
	}

	&-part {
		display: flex;
		width: 100%;
		border-top: solid 2px var(--silver);
	}

}
.column-double span, .data-double .data-table {
	width: 30%;
	margin: auto 5px;
}

.data-with-up,.column-with-up{
	width: 70% !important;
}

.column-address, .data-address {
	width: 20%;
}

.column-mail, .data-mail {
	width: 25%;
}

.column-name, .data-name{
  width: 15%;
}

.data-checkbox,.column-checkbox{
	width: 15% !important;
}

.double-with-up {
	width: 20%;
}

.top{

	&-table {
		display: flex;
		width: 100%;
		justify-content: space-between;
		background-color: #FFF;
		padding: 8px;
		border-radius: 8px;
	}
	&-filter {
		display: flex;
		width: 100%;
		justify-content: space-between;
		border-radius: 8px;
		border: solid 1px #e4e4e4;
		flex-wrap: wrap;
		margin: 10px auto;
		padding: 12px 8px;
	}

}

.table-filter {
	width: 30%;
	display: flex;
	flex-direction: column;
	margin: 6px 7px;
	font-size: 14px;

	&-row{
		display: flex;
	}
}

.operation{

	&-table {
		display: flex;
		width: 300px;
		justify-content: flex-start;
	}

	&-data {
		margin: auto 5px;
		color: #828899;
		background-color: white;
		border: solid 1px #F5F5F5;
		border-radius: 7px;
		font-size: 12px;
		display: flex;
		height: 37px;
		width: auto;

	}
}

.title-table {
	width: 70%;
	text-align: left;
}

.filter-name {
	font-weight: 500;
	font-size: 14px;
	margin: 1px 0;
	height: 20px;
}

.notification-bell {
	margin: auto 3px;
	vertical-align: sub;
}

.data-column {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  color: var(--grey);
  min-width: 25%;
}

.sort-select {
  background-color: inherit;
  margin: auto 5px;
  width: 100px;
  font-size: 12;
}

.operation-data > span {
	margin: auto;
}

.line-block{
	display: flex;

	&-data{
		width: 100%;
	}
}

.table-column-block{
	display: none;
}

.data-double-checkbox, .column-id{
	width: 10%;
}


.filling-text{
	overflow: hidden;
	white-space: nowrap;
}

.data-checkbox>p{
	display: none;
}


.data-table > span {
	margin: auto 0;
}


.filter{

	&-date-picker{
		display: flex;
		width: 100%;

		&>label{
			margin: auto 10px;
		}
	}

}

.activity{
	&-input-date{
		width: 25%;
	}

	&-history-nav {
		display: flex;
		position: relative;

		& article{
			display: flex;
			margin: auto 10px;
		}

		& label{
			margin: auto 5px;
		}

	}

}
