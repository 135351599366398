.mobileapp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}

.mobileapp-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.mobileapp-container ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
}

.mobileapp-container li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.installation-table {
  width: 100%;
  gap: 8px;
}

.installation-row {
  border: 1px solid #ccc;
  border-radius: 8px; /* Bordures légèrement arrondies */
  display: flex;
  align-items: flex-start;
  background-color: #f9f9f9; /* Fond léger */
  margin-bottom: 10px;
  box-shadow: 0px 0px 6px -4px var(--dark);
}

.installation-camera-icon {
  font-size: 18px; /* Réduction de la taille de l'icône */
  margin-right: 10px; /* Espace réduit entre l'icône et le titre */
}

.installation-row h3 {
  margin: 0;
  font-size: 16px; /* Réduction de la taille du titre */
  font-weight: bold;
  text-align: left;
}

.installation-row p {
  margin: 5px 0 0; /* Espacement réduit au-dessus du statut */
  font-size: 14px; /* Taille plus petite pour le statut */
  color: #666; /* Couleur légèrement plus claire pour le statut */
  text-align: left;
}

.installation-details {
  display: flex;
  flex-direction: column; /* Affiche le h3 et "Batterie" sur deux lignes */
  margin-left: 8px;
}

.installation-details {
  display: flex;
  flex-direction: column; /* Affiche le h3 et "Batterie" sur deux lignes */
  margin-left: 8px;
  margin-top: 0px;
}

.installation-details h3,
.installation-details p {
  margin: 0; /* Supprime toutes les marges par défaut */
  padding: 0; /* Supprime tous les padding */
  text-align: left; /* Aligne le texte à gauche */
}

.installation-row-header {
  display: flex;
  justify-content: space-between; /* Aligne les deux blocs à gauche et à droite */
  align-items: center; /* Aligne verticalement les éléments */
  width: 100%;
  padding: 10px;
}

.installation-left {
  display: flex;
  align-items: center;
}

.installation-right {
  position: absolute;
  margin-top: -60px;
  right: 30px; /* Colle cette section à droite */
  display: flex;
  align-items: left;
}

.installation-right p {
  margin: 0; /* Retire les marges pour s'assurer qu'il colle bien au bord */
}

.image-status {
  display: flex;
  flex-direction: row; /* Change l'orientation pour aligner les éléments horizontalement */
  gap: 15px; /* Espace entre les différents éléments */
}

.image-status-item {
  display: flex;
  align-items: center; /* Aligner l'icône et le texte sur la même ligne */
}

.status-icon {
  margin-right: 5px; /* Espace entre l'icône et le texte */
  font-size: 12px; /* Taille de l'icône plus petite */
  vertical-align: middle; /* Alignement de l'icône au texte */
}

.received {
  color: green; /* Couleur pour les images reçues */
}

.missing {
  color: red; /* Couleur pour les images manquantes */
}

.next {
  color: blue; /* Couleur pour la prochaine image attendue */
}

.installation-page {
  padding: 20px;
}

.image-container {
  display: flex;
  justify-content: flex-start; /* Aligne les images à gauche */
  align-items: center; /* Aligne verticalement les images au centre */
  gap: 8px; /* Ajoute un espace de 8px entre les images */
}

.device-image {
  width: 49%; /* Légèrement moins que 50% pour laisser de la place pour le gap */
  height: auto;
  object-fit: cover;
  border: none;
  margin: 0;
  padding: 0;
}

.address-header {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #333;
}

.install-button {
  padding: 15px 40px;
  font-size: 1.2rem;
  background-color: var(--akanthas-cyan);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.install-button:hover {
  background-color: var(--akanthas-yellow);
}

.installation-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.installation-detail-container h1 {
  font-size: 2rem; /* Taille du titre */
  margin-bottom: 20px;
  color: #333;
}

.installation-detail-container p {
  font-size: 1.2rem; /* Taille du texte */
  margin-bottom: 20px;
  color: #555;
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0;
  padding: 0;
  width: 100%;
}

.notification-row {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.notification-icon {
  margin-right: 12px;
  font-size: 1.3rem;
  color: var(--akanthas-cyan);
}

.notification-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.notification-date {
  font-size: 0.75rem;
  color: #999;
  margin-bottom: 8px;
  text-align: left;
}

.notification-details h3 {
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
}

.notification-details p {
  margin: 5px 0;
  font-size: 0.75rem;
  color: #666;
  text-align: left;
}

.device-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  position: relative;
}

.image-item {
  position: relative;
}

.expand-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.5); /* Niveau de transparence */
  font-size: 60px; /* Taille de l'icône */
  cursor: pointer;
  transition: color 0.3s;
}

.placeholder,
.zoom-placeholder {
  width: 45vw;
  height: 120px; /* Taille par défaut */
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: 14px;
  border: 1px solid #ccc;
}

.zoom-placeholder {
  margin-top: 8px;
  height: 200px; /* Taille du zoomed image */
  width: 92vw;
}

.datetime-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clock-icon {
  font-size: 14px;
  color: #333;
  margin-right: 8px;
}

.datetime-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0;
}