.flexbox-fix {
  display: none !important;
}

.color-selected {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  margin-left: 6px;
  border: solid 1px #ced4da;
}

.color {
  width: 20%;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 3px;
  border: solid 1px #ced4da;
}
