
.markercluster-map {
  height: 550px;
}

.leaflet-div-icon-map {
    background: transparent;
    border: none;
}

.leaflet-popup-content{
  width: 290px !important;
}

.content-map {
	width: 100%;
  border-radius: 0px;
  z-index: -1;
}

.mycluster{
  text-align: center;
}

.map{

  &-view{
    position: relative;

    &-legend{
      position: absolute;
      display: flex;
      z-index: 99999;
      background-color: rgba(255, 255, 255, 0.68);
      padding: 5px 10px;
      right: 8px;
      top: 10px;
      border-radius: 5px;
      max-width: 60%;

      & h6{
        margin: auto;
      }

      &-delivery{
        display: flex;
        margin: auto 15px;
        
      }

      &-circle{
        padding: 10px;
        border-radius: 50%;
        height: 0px;
        margin: auto 2px;
      }

      &-item{
        display: flex;
        margin: auto 5px;
        cursor: pointer;
      }
    }
  }

  &-popup-image{
    display: flex;
    justify-content: center;
  }
  
  &-icon{

    &-cluster {
      width: 32px;
      height: 32px;
      background-color: var(--akanthas-cyan);
      color: var(--white);
      display: flex;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 0px 20px 2px var(--akanthas-cyan);
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      padding: 4px;


      & span{
        margin: auto;
        text-align: center;

      }
    }

    &-marker{
      font-size: 28px; 
      opacity: 0.8;  
      border-radius: 50%;
    }

    &-green{
      color: var(--status-green);
      background-color: var(--status-green);
      box-shadow: 0px 0px 20px 2px var(--status-green);
    }

    &-orange{
      color: var(--status-orange);
      background-color: var(--status-orange);
      box-shadow: 0px 0px 20px 2px var(--status-orange);
    }
    
    &-red{
      color: var(--status-red);
      background-color: var(--status-red);
      box-shadow: 0px 0px 20px 2px var(--status-red);

      &-danger{
        color: var(--status-red-danger);
        background-color: var(--status-red-danger);
        box-shadow: 0px 0px 20px 2px var(--status-red-danger);
      }
    }
  }

}



