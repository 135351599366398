.fa-battery-empty,
.fa-battery-empty,
.fa-plug{
  color: var(--akanthas-red);
}

.fa-battery-three-quarters,
.fa-battery-full{
  color:var(--status-green);
}

.fa-battery-half,
.fa-battery-quarter{
  color:var(--orange);
}

.fa-battery-full,
.fa-battery-empty,
.fa-battery-three-quarters,
.fa-battery-half,
.fa-battery-quarter,
.fa-plug {
  margin: auto 10px auto 0px;
  text-align: right;
}

.header-user-info-text2 > div > .ico-user {
	color: var(--white);
}

.circle {
	padding: 10px;
	width: 10px;
	height: 10px;
	margin-right: 10px;
	border-radius: 50%;
}

.active {
	background-color: var(--status-green);
}

.not-active {
	background-color: var(--status-red);
}

.fa{

	&-envelope {
		margin: auto 5px;
		&-true{
			color:var(--akanthas-cyan)
		}
		&-false{
			color:var(--akanthas-red)

		}
	}

	&-file-pdf{
		color: var(--status-red);
	}

	&-long-arrow-alt-up{
		margin: auto 5px;
		font-size: 25px;
		color: var(--akanthas-yellow);
	}
}

.fa{
	&-comment-dots {
		margin: auto 5px;
		&-true{
			color:var(--akanthas-cyan)
		}
		&-false{
			color:var(--akanthas-red)

		}
	}
}

.arrow-positive{
	transform: rotate(45deg);
}

.arrow-negative{
	transform: rotate(145deg);
}

.information-line > .signin-icon-eye{
	top: 12px;
}

.data-icon{
	margin: auto 2px;

	&-hidden{
		
	}
}